$top-nav-background: #000;
$top-nav-color: #fff;
$top-nav-hover: #464646;

$main-nav-background: #ff4c4c;
$main-nav-color: #fff;
$main-nav-helper: #000;
$main-nav-hover: #cc0000;

$footer-background: $top-nav-background;
$footer-color: #999;

$pagination-text-color: #444;
$pagination-active-color: #ee3a43;
$pagination-active-text-color: #fff;