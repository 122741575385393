aside#sidebar1 {
  margin: 0 4px;
  padding: 5px;
  border-radius: 3px;
  background-color: #d3d3d3;
}

aside#sidebar2 {
  margin: 0 4px;
  padding: 5px;
  border-radius: 3px;
  background-color: #d3d3d3;
}

aside {
  a.register {
    display: block;
    font-size: 20px;
    line-height: 20px;
    color: red;
    text-transform: uppercase;
    text-align: center;
    margin: 10px 0px;
    text-decoration: none;

    &:hover {
      color: $main-nav-hover;
    }
  }

  ul.links {
    list-style-type: none;
    padding-left: 0;

    a {
      display: block;
      color: black;
      text-decoration: none;
      font-size: 24px;

      &:hover {
        color: $main-nav-hover;
      }
    }
  }

  dl {
    dt {
      font-weight: bold;
      font-size: 18px;
      margin: 0px 0 10px;
    }
    dd {
      font-size: 18px;
      margin: 0px 0;
    }
  }

  h3 ~ .info {
    font-size: 18px;
    overflow-wrap: break-word;
  }
}

#sidebar1, #sidebar2 {
  .burger-visible { display: none; }
}

@include media-max(615px) {
  body > main.two-sides {
    & > aside#sidebar2 {
      width: 100%;
    }
  }
}

@include media-max(950px) {
  body > main.one-side {
    flex-direction: column;

    & > article {
      flex: 0 1 auto;
    }

    & > aside#sidebar1 {
      width: 100%;
      margin: 4px 0;
      .burger-visible { display: block;}

      #sidebar1-content {
        transition: max-height .5s;
        overflow-y: hidden;
      }
      .burger-collapse { max-height: 0; }
      .burger-collapse-show { max-height: 999px; }
    }
  }
}

@include media-max(1100px) {
  body > main.two-sides {
    flex-wrap: wrap;

    & > aside#sidebar1 {
      width: 100%;
      margin: 4px 0;
      .burger-visible { display: block;}

      #sidebar1-content {
        transition: max-height .5s;
        overflow-y: hidden;
      }
      .burger-collapse { max-height: 0; }
      .burger-collapse-show { max-height: 999px; }
    }
  }
}