.item-details-page {
  .photo-details {
    display: flex;

    @import 'photos';
    @import 'summary';

    @media (max-width: 845px) {
      flex-wrap: wrap;
      justify-content: center;

      .photos { width: 100%; }
    }
  }

  .bid-tabs {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;

    .lot-tabs {
      flex: 1 0 0;
      width: 100%;

      .inv-desc-lbl {
        margin-top: 15px;
      }

      @import 'tabs';
    }
    .bid-box {
      width: 20em;
      margin-left: 15px;
      
      @import 'bidbox';
    }
    

    .machine-attachments, .lot-attachments {
      width: 40%;
      margin-left: 15px;
      
      .att-box {
        background-color: #444;
        color: #fff;
        padding: 15px;
      
        h2 {
          text-align: center;
          font-size: 16px;
          background-color: #EE3A43;
          margin: -15px -15px 15px -15px;
          padding: 15px;
        }

        & + .att-box {
          margin-top: 15px;
        }

        ul.pdfs {
          list-style-type: none;
          margin: 0;
          padding: 0;

          display: flex;
          flex-wrap: wrap;

          li {
            text-align: center;
          }

          .fa {
            font-size: 4em;

            & + span {
              display: block;
            }
          }

          a {
            color: #fff;
            &:hover {
              color: #EE3A43;
              text-decoration: none;
            }
          }
        }

        .videos {
          .youtube-container {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
            max-width: 100%;

            iframe, object, embed {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
          * + .video { margin-top: 15px; }
          .video {
            a {
              display: flex;
              align-items: center;
              color: #fff;
              &:hover {
                color: #EE3A43;
                text-decoration: none;
              }
            }
            
            .fa {
              font-size: 3.5em;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .lot-attachments {
      @media (max-width: 1200px){
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      .lot-tabs {
        order: 2;
      }
      .bid-box {
        order: 1;
        width: 95%;
        margin: 15px 0;
        align-self: center;
      }
      .machine-attachments, .lot-attachments {
        order: 3;
        width: 95%;
        margin: 15px 0;
        align-self: center;
      }
    }
  }

  .lot-summary {
    

    .machine-attachments, .lot-attachments {
      width: 40%;
      // margin-left: 15px;
      
      .att-box {
        background-color: #444;
        color: #fff;
        padding: 15px;
      
        h2 {
          text-align: center;
          font-size: 16px;
          background-color: #EE3A43;
          margin: -15px -15px 15px -15px;
          padding: 15px;
        }

        & + .att-box {
          margin-top: 15px;
        }

        ul.pdfs {
          list-style-type: none;
          margin: 0;
          padding: 0;

          display: flex;
          flex-wrap: wrap;

          li {
            text-align: center;
          }

          .fa {
            font-size: 4em;

            & + span {
              display: block;
            }
          }

          a {
            color: #fff;
            &:hover {
              color: #EE3A43;
              text-decoration: none;
            }
          }
        }

        .videos {
          .youtube-container {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
            max-width: 100%;

            iframe, object, embed {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
          * + .video { margin-top: 15px; }
          .video {
            a {
              display: flex;
              align-items: center;
              color: #fff;
              &:hover {
                color: #EE3A43;
                text-decoration: none;
              }
            }
            
            .fa {
              font-size: 3.5em;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .lot-attachments {
      @media (max-width: 1200px){
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      .lot-tabs {
        order: 2;
      }
      .bid-box {
        order: 1;
        width: 95%;
        margin: 15px 0;
        align-self: center;
      }
      .machine-attachments, .lot-attachments {
        order: 3;
        width: 95%;
        margin: 15px 0;
        align-self: center;
      }
    }
  }
}

.hidden-desktop {
  display: none;
}

@media (max-width: 768px) {
  .hidden-mobile {
    display: none;
  }
  .hidden-desktop {
    display: unset;
  }
}