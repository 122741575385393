
.split {
  display: flex;
  justify-content: space-between;
}

.split-around {
  display: flex;
  justify-content: space-around;
}

.spread {
  @extend .split;

   & > * {
     flex: 1 0 0;
   }
}

.split, .split-around, .spread {
  & > .halign-left { justify-content: flex-start; }
  & > .halign-center { justify-content: center; }
  & > .halign-right { justify-content: flex-end; }

  & > .valign-top { align-items: flex-start; }
  & > .valign-center { align-items: center; }
  & > .valign-bottom { align-items: flex-end; }

  &.valign-top > * { align-items: flex-start; }
  &.valign-center > * { align-items: center; }
  &.valign-bottom > * { align-items: flex-end; }
}