.nav {
  & > li {
    padding-bottom: 15px;
    & > a h3{
      padding: 0 15px;
      text-transform: uppercase;
      color: #ccc;
      border: none;
      border-radius: 0;
      font-weight: bold;
      font-size: 16px;
    }

    & + li {
      & > a{
        border-left: 1px solid #ccc;
      }
    }
  }
}

.nav-tabs {
  border-bottom: 1px solid #000;

  & > li {
    margin-bottom: 0;

    & > a h3{
      margin: 0;
      &:focus, &:hover {
        color: #6B6B6B;
        background-color: transparent;
      }
    }

    & > a {
      padding: 0;
      &, &:focus, &:hover {
        background-color: transparent;
        border-color: transparent;
        border-left-color: #ccc;
        border-radius: 0;
      }
    }

    &.active {
      & > a{
        &, &:focus, &:hover {
          border: none;
        }
      }
      & > a h3{
        &, &:focus, &:hover {
          color: #000;
        }
      }
    }

    & + li.active {
      & > a {
        &, &:focus, &:hover {
          border-left: 1px solid #ccc;
        }
      }
    }
  }
}