.btn-mu {
  display: inline-block;
  padding: 15px;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  font-size: smaller;

  &:hover {
    text-decoration: none;
  }

  &.btn-dark {
    color: #fff;
    background-color: #444;
    &:hover {
      background-color: #7F7F7F;
    }
  }
  &.btn-red {
    color: #fff;
    background-color: #EE3A43;
    &:hover {
      background-color: #ED848A;
    }
  }
}
button.btn-mu {
  border: none;
}
.btn-dark {
  color: #fff;
  background-color: #444;
  &:hover {
    background-color: #7F7F7F;
  }
  &.watched{
    color: #ee3a43
  }
}

.btn-group {
  & > .btn-mu {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    &:first-child:not(:last-child) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-left: 1px solid transparent;
    }

    &:last-child:not(:first-child) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid transparent;
    }
    
    & + .btn-mu {
      border-left: 1px solid transparent;
    }

    &.btn-red {
      border-color: #EE3A43 !important;
      color: #EE3A43;
      background-color: white;
      &:hover {
        color: #fff;
        background-color: #ED848A;
      }

      &.active {
        color: #fff;
        background-color: #EE3A43;
        &:hover {
          background-color: #ED848A;
        }
      }
    }
  }
}