.auctions-article {
  width: calc(100% - 230px);

  @include media-max(950px) {
    width: 100%;
  }
}

main.auctions-list {
  margin-top: 10px;
  padding: 0 10px;

  & > h2 {
    background: #d3d3d3;
    text-align: center;
    font-weight: 900;
    margin: 0;
  }

  .auction {
    .split {
      .button {
        .btn {
          font-size: 22px;
          padding: 18px 16px;
        }
      }

      @include media-max(600px) {
        flex-direction: column;
        .button {
          text-align: right;
        }
      }
    }

    .lots-scroll {
      display: flex;
      overflow-x: auto;

      .lot {
        flex: 0 0 auto;
        max-width: 280px;

        & + .lot {
          margin-left: 1%;
        }
        img {
          border: 1px solid red;
        }
      }
    }
  }
}

.category-list {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    font-size: 21px;

    &::after {
      content: '\00a0|\00a0';
    }
    &:last-child::after {
      content: '';
    }
  }

  a {
    color: #00f;
    text-decoration: none;
  }
}

footer.payment-info {
  h3 {
    color: $main-nav-background;
    font-weight: 900;
  }
}

.lot-winning-user{
  margin: 0px !important;
  &.tile{
    width: 100%;
  }

}

.lot-bid-info{
  margin: 0px !important;
  &.tile{
    width: 100%;
  }
  &.list{
    width: 20%;
  }
}

.lot-current-bid{
  margin: 0px !important;
  &.tile{
    width: 100%;
  }
  &.list{
    width: 20%;
  }
}

.lot-number-bids{
  margin: 0px !important;
  &.tile{
    width: 100%;
  }
  
}
.lot-time-left{
  margin: 0px !important;
  &.tile{
    width: 100%;
  }
  &.list{
    width: 35%;
  }
}