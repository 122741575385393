body > header {
  margin-bottom: .5%;
  
  nav.top {
    background-color: $top-nav-background;

    display: flex;
    justify-content: space-between;
    align-items: center;
    ul {
      list-style-type: none;
    }
    a {
      color: $top-nav-color;
      display: block;
      padding: 5px 5px 10px;
      text-decoration: none;

      &:hover {
        background-color: $top-nav-hover;
      }
    }
    
    li:not(.social-icon){
      clear: both;
    }

    li.social-icon {
      text-align: center;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;

      a {
        border: 2px solid #ee3a43;
        color: #ee3a43;
        border-radius: 50%;
        padding: 2px;
        display: inline;
      }
    }

    a.phone{
      margin-left: 20px;
    }

    ul.inline {
      display: flex;
      padding: 15px;
      margin: 0 20px 0 0;

      li.social-icon {
        margin-left: 10px;
        margin-top: 0;
      }

      a {
        text-transform: uppercase;
        font-style: italic;
      }
    }

    
  }

  .split {
    padding: 10px 0;

    .logo {
      img {max-height: 101px;}

      @include media-min(1300px) {
        margin-left: 8%;
      }
    }
    .machine-images {
      margin-right: 20px;
      img {
        max-height: 102px;
        max-width: 114px;
        border: 2px solid #FA9196;
        border-radius: 2px;
        margin: .5em;
        display: none;
      }

      @include media-min(500px) { img:first-child{ display: inline; } }
      @include media-min(678px) { img:nth-child(2) { display: inline; } }
      @include media-min(852px) { img:nth-child(3) { display: inline; } }
      @include media-min(1026px) { img:nth-child(4) { display: inline; } }
      @include media-min(1200px) { img:nth-child(5) { display: inline; } }
    }
    
    @include media-max(500px) {
      justify-content: center;
      .logo img { max-width: 100%; }
    }
  }

  nav.main {
    display: flex;
    justify-content: center;

    a:first-child {
      display: inline-flex;
      justify-content: flex-end;
    }
    
    a {
      position: relative;
      background-color: $main-nav-background;
      color: $main-nav-color;
      min-height: 80px;
      padding: 0 25px;
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      flex: 1 1;

      span {
        flex: 1 0 0;
        text-align: center;
        font-weight: bold;
        white-space: nowrap;
      }
      small {
        color: $main-nav-helper;
        display: block;
        white-space: nowrap;
        font-size: 14px;
      }

      &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        border-color: #fff;
        border-style: solid;
        border-width: 40px 0 40px 20px;
      }
      &::before {
        border-color: $main-nav-background $main-nav-background $main-nav-background #fff;
        left: 0;
      }
      &::after {
        border-left-color: $main-nav-background;
        right: 0;
      }

      &:first-child {
        flex: 1 0 ;
        span { text-align: right; }
        &::before { border-width: 0; }
      }
      &:last-child {
        flex: 1 0 0;
        justify-content: flex-start;
        span { flex-grow: 0; }
        &::after { border-width: 0; }
      }

      &:hover {
        background-color: $main-nav-hover;
        &::before {
          border-color: $main-nav-hover $main-nav-hover $main-nav-hover #fff;
        }
        &::after {
          border-left-color: $main-nav-hover;
        }
      }

      @include media-max(1395px) {
        font-size: 16px;
        small { font-size: 10px; }
      }
      @include media-max(1160px) {
        font-size: 14px;
        min-height: 40px;
        padding: 0 15px;

        &::before, &::after {
          border-width: 20px 0 20px 10px;
        }
      }
    }
  }

  .burger-visible { display: none; }
  .btn-burger {
    margin: 5px;
    background-color: transparent;
    color: $top-nav-color;
    border-color: $top-nav-color;

    &:hover {
      background-color: $top-nav-hover;
      color: $top-nav-color;
    }
  }

  @include media-max(950px) {
    nav.main.burger-hide, nav.top ul.inline { display: none; }
    .burger-visible { display: block; }

    nav.top {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}