.flex-carousel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: 10px auto 0;

  button.previous, button.next {
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    font-size: 10px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .glyphicon { position: static; }
  }
  button.previous { margin-right: 10px; }
  button.next { margin-left: 10px; }

  .flex-carousel-list, .flex-carousel-list-item {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .flex-carousel-list {
    /*display: flex;
    align-items: center;*/
    overflow: hidden;
    white-space: nowrap;

    .flex-carousel-list-item {
      display: inline-flex;
      align-content: center;
      justify-content: center;
      
      height: 100px;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      & + .flex-carousel-list-item {
        margin-left: 5px;
      }
    }
  }
}