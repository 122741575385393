.lot-summary {
  flex: 1 1 auto;
  margin-left: 20px;

  .title-summary {
    display: flex;

    .title-info {
      flex: 1 1 auto;
    }
    .title-buttons {

    }
  }
  
  h1 {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
  }

  .italics {
    font-style: italic;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    & > span + span {
      margin-left: 1.2em;
    }

    @media (max-width: 1024px) {
      justify-content: space-between;
      & > span + span {
        margin-left: 0;
      }
    }
  }
  .category { margin-left: 1.5em; }

  .alert {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: #FFEEEF;
    border: 1px solid #ee3a43;
    border-left-width: 4px;
    border-radius: 2px;
    margin: 20px 0 5px;
    padding: 0;

    & > * {
      text-align: center;
      font-weight: bold;
      min-width: 185px;
      margin: 10px 5px;
    }
  }
  .alert-helper {
    font-size: 12px;
    text-align: center;
  }

  .summary-flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .summary-box {
      flex: 1 0 33.3%;
      min-width: 165px;
      margin-top: 30px;
      text-transform: uppercase;
      .lbl {
        font-weight: bold;
      }
    }
    .winning-bidder{
      text-transform: none;
    }
    &.inventory {
      justify-content: flex-start;
      .summary-box {
        padding-bottom: 0px;
        flex: 0 0 100%;
        margin-bottom: 0px;
        margin-top: 0px;
        font-size: 12px;
      }
    }
  }

  .summary-buttons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    margin-top: 10px;

    &.inventory {
      justify-content: flex-start;
    }

    .btn-mu {
      margin: 5px 5px; min-width: 110px;

      &.watched {
        color: #fff;
        background-color: #ee3a43;
      }
    }
  }
}