dl.manufacturers {
  & > dt {
    float: left;
    margin-right: .25em;

    &::after {
      content: ':';
    }
  }
}

.form-group.required {
  label::after {
    content: '*';
    color: red;
    position: absolute;
    font-size: 1.3em;
  }
}

.intl-tel-input {
  width: 100%;
}

.news-recent-entries {
  & > h2 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    border-top: 2px solid black;
    border-bottom: 1px dotted black;
  }

  & > ul {
    list-style-type: none;
    padding-left: 0;
  }
}

.news-item + .news-item {
  margin-top: 3em;
}
.news-item {
  header {
    &::after {
      content: ' ';
      display: block;
      width: 100px;
      height: 1px;
      border-bottom: 1px solid #888;
    }
    h1, h2 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0;

      a {
        color: black;
        &:hover {
          text-decoration: none;
          color: #090;
        }
      }
    }
    time {
      font-style: italic;
      color: #888;
      font-size: 12px;
    }
  }
}

.hasError {
  border-color: red;
}

h4 .checkbox-inline {
  font-size: 14px;
  margin-left: 5px;
}

$pill-height: 40px;
.nav-pills.step-progress {
  a {
    background-color: $main-nav-background;
    color: $main-nav-color;
    height: 40px;

    &:focus {
      background-color: $main-nav-background;
      color: $main-nav-color;
    }

    &::before, &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      border-color: #fff;
      border-style: solid;
      border-top-width: $pill-height / 2;
      border-right-width: 0;
      border-bottom-width: $pill-height / 2;
      border-left-width: $pill-height / 4;
    }

    &::before {
      border-color: $main-nav-background $main-nav-background $main-nav-background #fff;
      left: 0;
    }
    &::after {
      border-left-color: $main-nav-background;
      right: 0;
    }

    &:hover {
      background-color: $main-nav-hover;
      &::before {
        border-color: $main-nav-hover $main-nav-hover $main-nav-hover #fff;
      }
      &::after {
        border-left-color: $main-nav-hover;
      }
    }
  }

  .active {
    a {
      background-color: $main-nav-hover;

      &:focus {
        background-color: $main-nav-hover;
      }
      &::before {
        border-color: $main-nav-hover $main-nav-hover $main-nav-hover #fff;
      }
      &::after {
        border-left-color: $main-nav-hover;
      }
      &:hover {
        background-color: $main-nav-hover;
      }
    }
  }

  .active ~ li {
    a {
      background-color: inherit;
      &::before, &::after {
        border-color: transparent;
      }
    }

    &:not(.disabled) {
      a {
        color: $main-nav-hover;
      }
    }
  }

  li:first-child {
    a::before { border-width: 0; }
  }

  li:last-child {
    a {
      pointer-events: none;
      cursor: default;
    }
  }

  li.active:last-child {
    a {
      background-color: green;
      &::before {
        border-color: green green green #fff;
      }
      &::after {
        border-left-color: green;
        border-width: 0;
      }
    }
  }

  li.finished {
    a {
      pointer-events: none;
      cursor: default;
    }
  }
}

.invoice-bar {
  background: #EAEAEA;
  padding:5px;
  border-top:1px solid #CCC;
  margin-top:5px;
  margin-bottom: 5px;
  margin-left: -5px;
  margin-right: -5px;
}