@mixin fa($content) {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: $content;
}

@viewport{
  zoom: 1.0;
  width: extend-to-zoom;
}

.container {
  width: 72%;
  padding-right: 0;
  padding-left: 0;

 /* @include media-max(950px) {
    width: 100%;
  }*/
}

form.login {
  .btn {
    color: red;
  }

  &::after {
    display: table;
    content: ' ';
    clear: both;
  }
}

form.auction-search {
  input, select {
    box-shadow: 5px 6px 5px 0px rgba(168, 168, 168, .75);

    &.form-control:focus {
      box-shadow: 5px 6px 5px 0px rgb(168, 168, 168);
    }
  }

  @include media-min(768px) {
    input.form-control { width: 350px; }
  }

  .btn-link {
    font-size: 1.6em;
    text-transform: uppercase;
    color: black;

    &:hover {
      text-decoration: none;
    }
  }
}

.media-main {
  cursor: pointer;
  position: relative;

  .openGallery {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 7px;
    font-size: 23px;
    line-height: 23px;
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 0px 1px 5px #000;
    transition: .1s;

    &:hover {
      font-size: 25px;
      line-height: 25px;
      color: rgba(255, 255, 255, 1);
    }
  }
}

.list-view{
  .media-main{
    .openGallery{
      right:20px;
    }
  }
}