& {
  background-color: #444;
  color: #fff;
  padding: 15px;

  h2 {
    text-align: center;
    font-size: 16px;
    background-color: #EE3A43;
    margin: -15px -15px 15px -15px;
    padding: 15px;
  }

  .form {
    margin-top: 15px;

    .lbl {
      font-weight: bold;
    }
  }

  select, input {
    width: 100%;
    background-color: #c7c7c7;
    color: #444;
    font-weight: bold;
    padding: 10px;
    border: 1px solid #c7c7c7;
  }

  input.higher-bid {
    color: #EE3A43;
    background-color: #ffeeef;
    border: 1px solid #EE3A43;
  }

  .select2-container--default {
    width: 100% !important;

    .select2-selection--single {
      border-radius: 0;
      border: none;
      background-color: #c7c7c7;
      color: #444;
      font-weight: bold;
      height: auto;
      display: flex;
      align-items: center;

      .select2-selection__rendered {
        padding: 10px 20px 10px 10px;
        flex: 1 0 auto;
      }

      .select2-selection__arrow {
        position: static;
        display: flex;
        justify-content: center;
        align-items: center;

        b {
          position: static;
          border-color: #444 transparent transparent transparent;
        }
      }
    }

    &.select2-container--open {
      .select2-selection--single {
        .select2-selection__arrow b {
          border-color: transparent transparent #444 transparent;
        }
      }
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  .bid-terms {
    margin: 15px 0;
  }

  .bid-button {
    display: flex;
    justify-content: center;

    .btn-mu {
      min-width: 130px;
    }
  }

  .bid-stats + .bid-button {
    margin-top: 15px;
  }

  a:not(.btn-mu):not(.btn) {
    color: #fff;
    text-decoration: none;
    border-bottom: 1px dotted #fff;
  }

  .bid-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;


    & > * {
      min-width: 15em;
      flex: 1 1 auto;
    }
  }
}