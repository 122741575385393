
.filter-bar{
  padding:20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @include media-max(767px){
    padding:10px;
    flex-direction: column;
    align-items: inherit;
  }
}

.inventory-dropdown{
  width: 15%;
  @include media-max(767px){
    width: 100%;
  }
}

.request-quote{
  img{
    max-width: 100%;
  }
}

.inv-bar{
  display: flex;
  justify-content:space-between;
  .inv-pagination{
    display: block;
    margin: 0;
  }
}

.flexend{
  justify-content: flex-end;
}

.machines-to-quote{
  padding:10px 30px;
  border:solid black 1px;
  max-width: 80%;
  margin: auto;
  display: flex;
  justify-content:space-between;
  flex-direction: column;
  .single-machine{
    display: flex;
    justify-content: space-between;
    align-items:center;
    &:not(:last-child){
      border-bottom: solid black 1px;
    }
    .remove{
      cursor: pointer;
    }
    .notAvailable {
      color: red;
    }
  }
}

.form-control{
  &.min-width{
    width: auto;
  }
}
#quote-form{
  align-items:flex-end;
  max-width:1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  .form-group{
    margin:10px 10px;
  }
  .phone-number-group{
    display: flex;
    .phone-group{
      max-width: 175px;
      margin-right: 10px;
    }
    .ext-group{
      max-width: 100px;
    }
  }

}

.thankyou{
  margin:80px 0;
  &>*{
    text-align: center;
    margin: auto;
  }
}

.inventory-footer{
  display: flex;
  justify-content:space-around;
  .machine-box{
    h2{
      font-size: 18px;
      text-align: center;
      padding:15px;
      background-color: #ee3a43;
      color: white;
      font-weight:bold;
      margin-bottom: 0;
    }
    .info-text{
      background-color: #444444;
      color: white;
      padding: 15px;
      text-align: center;
    }
  }

}

.sell-form{
  &>*{
    max-width:500px;
  }
  input.form-control.lbl {
    padding: 0 3px;
    line-height: 1;
    height: 1.5em;
    font-weight: bold;
  }
  .flex-row{
    flex-wrap: 0;
    margin-top: 15px;
    .form-control{
      width: calc(100% - 50px);
    }
  }

}

.news-header {
  color: white;
  background-color: #ff4c4c;
  margin: -5px;
  padding: 5px;
  border: none !important;
  border-radius: 3px 3px 0 0;
}

.publish-date {
  background-color: #ff4c4c;
  padding: 5px;
  margin-left: -5px;
  margin-right: -5px;
}

.news-link {
  a { 
    font-size: 16px !important; 
    .read-more {
      text-align: right;
      width: 100%;
    }
    span {
      color: #ff4c4c;
      font-size: 14px;
    }
  }
}
