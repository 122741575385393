.flex-row{
  display: flex;
  flex-wrap: wrap;
  &.column-4:not(.list-view)>*{
    @media (min-width: 951px) and (max-width: 1077px) {
      width: 45%;
    }
    @media (min-width: 440px) and (max-width: 840px) {
      width: 45%;
    }
    @media (max-width: 439px) {
      max-width: 45%;
      min-width:135px;
      flex-grow: 1;
    }
    width:23%;
    min-width:200px;
    margin-right: 1%;
    margin-left: 1%;
  }
  &.list-view{
    flex-direction: column;
    img{
      max-width:220px;
      @include media-max(619px){
        max-width: 100%;
        padding-right: 0;
        margin: auto;
      }
    }
    .list-only{
      display: block;
    }
    &>*{
      width: 100%;
      display: flex;
      padding:10px 0;
      justify-content:space-between;

      border-bottom:solid 1px black;
      @include media-max(619px){
        flex-wrap: wrap;
        justify-content: center;
      }
      &>*{
        margin: 0 20px;
      }
      .media-main{
        @include media-max(619px){
          width: 100%;
          padding-right: 0;
        }
        padding-right: 20px;
        margin: 0 0;
      }
      .machine-description, .lot-description{
        flex-grow: 2;
        flex-shrink: 1;
      }
      .lot-number-bids, .lot-time-left {
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: 740px){
  .flex-row.list-view .machine-tile{
    flex-wrap: wrap;
    justify-content: center;
    
    .media-main, a.machine-description{
      width: 45%;
    }
    .request-quote {
      width: 100%;
      text-align: center;
    }
  }
}

.view-select{
  &.active{
    cursor: default;
    background-color: grey;
  }
  margin-bottom: 20px;
}

.lot-tile {
  a.lot-description, div.lot-description {
    text-decoration: none;
    width: 30%;
  }
}

.lot-tile-header{
  width: 100%;
  .media-placeholder{
    margin: 0px 20px;
    width: 220px;
  }
  .description-placeholder{
    margin: 0px 20px;    
    width: 30%;
  }
  .price{
    //The margins for the resizing divs get a little wacky
    margin: 0px 20px 0px 40px;
    width: 15%;
  }

  .bid-info{
    // margin-left: 20px;
    width: 20%;
  }

  .lot-time-left{
    width: 35%;
  }
}