body > footer {
  background-color: $footer-background;
  color: $footer-color;
  margin-top: 20px;
  nav {
    flex: 3 0 0;
    display: flex;
    justify-content: space-between;
    @include media-max(600px) {
      flex-wrap: wrap;
      flex: 0 1 0;
      > div {
        width: 100%;
      }
    }
    ul {
      list-style-type: none;
      width: 100%;
      height: 138px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: space-around;
      margin: 0;
      padding: 10px;

      @include media-max(385px) {
        height: 150px;
      }

      li {
        margin-bottom: 5px;

        &::before {
          @include fa("\f0da");
          color: #ee3a43;
          margin-right: 5px;
        }
        a, a:hover {
          color: #ee3a43
        }
      }
    }
  }
  .snd-container-fluid{
    display: flex;
    justify-content: space-evenly;
  }
  #newsletterFooter {
    color: #dacecf;
    font-style: italic;

    h4 {
      color: #fcf1f2;
      text-transform: uppercase;
    }
    input {
      color: #000;
      font-style: normal;
      &::placeholder {
        font-style: italic;
      }
    }
    button {
      font-style: normal;
    }
  }

  form {
    flex: 2 0 0;
    padding: 5px;
  }

  @include media-max(780px) {
    height: auto;
    flex-direction: column;
    padding-bottom: 5px;

    nav { order: 2; }
    form { order: 1; }
  }
}