body > main {
  display: flex;

  & > aside {
    width: 230px;

    h3 {
      text-align: center;
      border-bottom: 1px solid black;
      color: red;
    }
  }
  & > article {
    flex: 1 0 0;
    width: 100%;

    dd > p {
      overflow-wrap: break-word;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}