.blog-sidebar {
  &:first-child > h2 { margin-top: 5px; }
  & > h2 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    border-top: 2px solid black;
    border-bottom: 1px dotted black;
  }

  & > ul {
    list-style-type: none;
    padding-left: 0;
  }

  &.recent-entries {
    .date {
      margin-left: 3px;
    }
  }

  &.categories {
    li {
      display: flex;
      justify-content: space-between;
    }

    a.feed {
      color: #f60;
    }
  }
}

.blog-post + .blog-post {
  margin-top: 4em;
}
.blog-post {
  img {
    max-width: 100%;
    height: auto;
  }
  header {
    &::after {
      content: ' ';
      display: block;
      width: 100px;
      height: 1px;
      border-bottom: 1px solid #888;
    }
    h1, h2 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0;

      a {
        color: black;
        &:hover {
          text-decoration: none;
          color: #090;
        }
      }
    }
    time {
      font-style: italic;
      color: #888;
      font-size: 12px;
    }
  }

  main {
    a.more {
      &::before {
        content: '[';
      }
      &::after {
        content: ']';
      }
      &:hover {
        text-decoration: none;
      }
      i.fa {
        margin-left: 3px;
      }
    }
  }

  footer {
    &::before {
      content: ' ';
      display: block;
      width: 100px;
      height: 1px;
      border-top: 1px solid #888;
    }

    ul.tag-list {
      list-style-type: none;
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;

      li:first-child {
        font-weight: bold;
        margin-right: 3px;
        &::after {
          content: ': ';
        }
      }
      li:not(:first-child) + li {
        &::before {
          content: ', ';
        }
      }
    }
  }
}

ul.blog-pagination {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
}