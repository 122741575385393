.pagination {
  margin: 0 !important;

  & > li {
    & > a, & > span {
      &, &:focus, &:hover {
        color: $pagination-text-color;
      }
    }
  }
  & > .active {
    & > a, & > span {
      &, &:focus, &:hover {
        background-color: $pagination-active-color;
        border-color: $pagination-active-color;
        color: $pagination-active-text-color;
      }
    }
  }
}