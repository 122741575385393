.photos {
  width: 30%;
  min-width: 245px;

  & > img.media-main {
    margin: 0 auto;
  }

  @media (max-width: 845px) {
    .media-list {
      padding: 0 30px;
    }
  }
}

