.mu-dialog {

  .modal-dialog {

    .modal-content {
      background-color: #444;
      color: #fff;
      display: flex;
      flex-direction: column;

      .modal-header {
        background-color: #EE3A43;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        &::before, &::after {
          content: none;
        }
      }

      .modal-body {
        flex: 1 1 auto;
        max-height: calc(100vh - 210px);
        overflow-y: auto;

        .modal-header {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }

      .table {
        tr.success {
          td, th {
            background-color: #8cb77a;
          }
        }
        td.success, th.success {
          background-color: #8cb77a;
        }
      }

      .help-block {
        color: #bfbfbf;
        font-size: .95em;
      }

      .bg-info {
        background-color: #bfbfbf;
        color: #000;
        padding: 5px;

        .modal-header {
          margin: -5px -5px 2px;
          padding: 5px;
          color: #fff;
        }
      }

      .modal-footer {
        display: flex;
        justify-content: center;
        align-items: center;

        .left {
          margin-right: auto;
        }
        .right {
          margin-left: auto;
        }

        .btn, .btn-mu {
          margin: 0 5px;
        }
      }
    }

  }

}