.checkbox.checkbox-inline label::after {
  line-height: 16px;
}

.input-group {

  & > .intl-tel-input {
    display: table-cell;

    .flag-container {
      z-index: 4;
    }

    .form-control {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:first-child {
      .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child {
      .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &.phone-with-ext {
    .intl-tel-input {
      width: 70%;
    }
  }

  .form-group {
    .form-control {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    & > .intl-tel-input {
      display: table-cell;

      .flag-container {
        z-index: 4;
      }
    }

    &:first-child {
      .form-control {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    &:last-child {
      .form-control {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.form-group.required {
  label::after {
    content: '*';
    color: red;
    position: absolute;
    font-size: 1.3em;
  }
}