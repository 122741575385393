.home-blocks {
  flex-wrap: wrap;

  @include media-min(400px) {
    padding: 0 15px;
  }

  .block {
    background-color: #d3d3d3;
    padding: 0 15px;
    min-width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    @include media-max(400px) {
      min-width: 100%;
      padding: 0;
    }

    &:nth-child(even) { background-color: #bdbdbd; }
    h4, h3 {
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 10px;

      a {
        color: black;
        &:hover {
          text-decoration: none;
        }
      }
    }

    img {
      height: 185px;
      margin-bottom: 2%;
    }

    & > .block-content {
      width: 85%;
      text-align: center;
    }
  }
}

h2.dots-bullet {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;

  &::before {
    content: '';
    background-image: url('/images/dots.png');
    display: inline-block;
    width: 13px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
  }
}

.latest-news {
  & > .split-around {
    flex-wrap: wrap;
  }

  .news-card {
    width: 22%;
    min-width: 262px;
    box-shadow: 0px 1px 3px #ccc, 0px 0px 3px #ececec;
    margin: 1.25rem 1rem;

    @include media-max(305px) {
      min-width: 22%;
      width: 100%;
    }

    header {
      background-color: #ff4c4c;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      padding: 18px 1.25rem 10px;
      text-transform: uppercase;
    }
    article {
      padding: 0.75rem 1.25rem;
    }
    footer {
      .btn {
        color: red;
        text-transform: uppercase;
      }
    }
  }
}

.our-partners {
  & > .split-around {
    flex-wrap: wrap;
  }

  .partner-card {
    padding: 10px;
    box-shadow: 0px 1px 3px #ccc, 0px 0px 3px #ececec;
    margin: 1.25rem 1rem;
    min-width: 262px;
    width: 22%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    @include media-max(305px) {
      min-width: 22%;
      width: 100%;
    }

    img {
      max-height: 185px;
    }
  }
}

.home-cms {
  img {
    max-width: 100%;
    height: auto;
  }
}